enum EstimatePartEnum {
    LocalSmeta = 'local',
    ChapterSmeta = 'chapter',
    SubchapterSmeta = 'subchapter',
    JobSmeta = 'job',
    MaterialSmeta = 'material',
    local = 'LocalSmeta',
    chapter = 'ChapterSmeta',
    subchapter = 'SubchapterSmeta',
    job = 'JobSmeta',
    material = 'MaterialSmeta',
}

export enum EstimateMaterialTypeEnum {
    LocalSmeta = 1,
    ChapterSmeta,
    SubchapterSmeta,
    JobSmeta,
    MaterialSmeta,
}

export default EstimatePartEnum;
