import { Component, PropSync, Vue } from 'vue-property-decorator';

// Utils
import { Filter, FilterElementSlot, FilterPanelSlotType } from '@/utils/Filter';
import { get } from 'lodash';
import ICountedOption from '@/ship/Models/ICountedOption';

// Enums
export enum EstimatePageTabs {
    Positions,
    Additional,
    Acts,
}

export enum AdditionalEstimatePageTabs {
    Positions,
    Acts = 2,
}

export enum ActsPageTabs {
    Positions,
}

@Component({
    components: {},
})
export default class VEstimatePageFilterMixin extends Vue {
    @PropSync('tabValue', { type: Number, required: true })
    public tab!: EstimatePageTabs;

    @PropSync('filterValue', { type: Object, default: () => ({}) })
    public filters!: ICountedOption[];

    showFilter = false;
    selectorBind = {
        label: 'label',
        multiple: true,
        trackBy: 'value',
        reduce: (x: unknown) => get(x, 'value'),
        options: [
            { label: '111', value: 1 },
            { label: '222', value: 2 },
            { label: '333', value: 3 },
        ],
    };

    estimatePageFilters: FilterElementSlot[] = [
        new Filter(
            'expenses',
            this.$t('page.estimates.estimate.expenses'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        new Filter(
            'level',
            this.$t('page.estimates.estimate.level'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        Filter.emptyElement(),
        new Filter(
            'remains',
            this.$t('page.estimates.estimate.remains'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        new Filter(
            'units',
            this.$t('page.estimates.estimate.units'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        Filter.clearButton(),
        Filter.clearButton(),
        Filter.saveButton(),
    ];

    additionalPageFilters: FilterElementSlot[] = [
        new Filter(
            'status',
            this.$t('status'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        new Filter(
            'documentType',
            this.$t('documentType'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        Filter.emptyElement(),
        new Filter(
            'costFrom',
            this.$t('costFrom'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        new Filter(
            'costTo',
            this.$t('costTo'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        Filter.clearButton(),
        Filter.saveButton(),
    ];

    actsPageFilters: FilterElementSlot[] = [
        new Filter(
            'status',
            this.$t('status'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 6',
        ),
        Filter.emptyElement(),
        new Filter(
            'costFrom',
            this.$t('costFrom'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        new Filter(
            'costTo',
            this.$t('costTo'),
            [],
            FilterPanelSlotType.Select,
            this.selectorBind,
            'grid-column: span 3',
        ),
        Filter.clearButton(),
        Filter.saveButton(),
    ];

    get filtersList() {
        switch (this.tab) {
            case EstimatePageTabs.Positions:
                return this.estimatePageFilters;
            case EstimatePageTabs.Additional:
                return this.additionalPageFilters;
            case EstimatePageTabs.Acts:
                return this.actsPageFilters;
            default:
                return [];
        }
    }

    get filtersStorageName() {
        switch (this.tab) {
            case EstimatePageTabs.Positions:
                return 'estimate-positions-filters';
            case EstimatePageTabs.Additional:
                return 'estimate-additional-filters';
            case EstimatePageTabs.Acts:
                return 'estimate-acts-filters';
            default:
                return '';
        }
    }

    public toggleFilter() {
        this.showFilter = !this.showFilter;
    }
}
